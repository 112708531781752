import Default from '../img/Default.png'
import Default1 from '../img/Default1.png'
import Default2 from '../img/Default2.png'
import Default3 from '../img/Default3.png'
import Default4 from '../img/Frame.png'
export const valuesData = [
  {
    id: 1,
    image: Default,
    desc: 'Integrity, Trust, Respect and Transparency are our basics.',
  },
  {
    id: 2,
    image: Default1,
    desc: ` We believe that there are no limits to what we can achieve,
                  and we are always willing to explore new products, markets,
                  and opportunities.`,
  },
  {
    id: 3,
    image: Default2,
    desc: 'We always innovate new solutions with speed and flexibility',
  },
  {
    id: 4,
    image: Default3,
    desc: ` Starting business in an intelligent way is the secret of its
                  success.`,
  },
  {
    id: 5,
    image: Default4,
    desc: `Actual differences require creative thought, courage and
                  initiation.`,
  },
]
