import React from 'react'
import './CoreValueCard.css'
const CoreValueCard = (props) => {
  console.log('props', props)
  return (
    <div className="core-value-card" key={props.valueItem.id}>
      <div className="core-value-card-image">
        <img src={props.valueItem.image} />
        <div className="core-value-card-desc">
          <p>{props.valueItem.desc}</p>
        </div>
      </div>
    </div>
  )
}

export default CoreValueCard
