import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './FirstTab.css'
import image10 from '../../img/image10.png'
export const FirstTab = () => {
  return (
    <Container fluid>
      <div className="first">
        <Row>
          <div className="f-row">
            <Col sm={6}>
              <div className="f-image">
                <img src={image10} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="f-desc">
                <h5>
                  We have a diverse portfolio and candidate of products, with a
                  focus in the categories of Respiratory, Hormones,
                  contraceptive and oncology and other therapeutic areas, where
                  we can meaningfully meet serious medical needs in the market
                </h5>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
