import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import './OurServices.css'
import { services } from '../../data/services'
import money1 from '../../img/money1.png'
import money2 from '../../img/money2.png'
import money3 from '../../img/money3.png'
import money4 from '../../img/money4.png'
import money5 from '../../img/money5.png'
import chart from '../../img/chart.png'
import briefcase from '../../img/briefcase.png'
import stickynote from '../../img/stickynote.png'
export const OurServices = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [MenuDataList, setMenuDataList] = useState(services)
  // const filter = (type) => {
  //   setMenuDataList(services.filter((about) => about.type === type))
  // }

  return (
    <Container fluid>
      <Row>
        <div className="s-title">Our Services</div>
      </Row>
      <Row>
        <div className="a-wrapper">
          <Col sm={6}>
            <ul className="a-menu">
              <li
                className={`${activeTab == 0 && 'active-list-item'}`}
                onClick={() => {
                  setActiveTab(0)
                }}
              >
                <span>
                  <img src={activeTab == 0 ? money3 : chart} />
                </span>
                Marketing Research Studies
              </li>
              <li
                className={`${activeTab == 1 && 'active-list-item'}`}
                onClick={() => {
                  setActiveTab(1)
                }}
              >
                <span>
                  <img src={activeTab == 1 ? stickynote : money2} />
                </span>
                Registration
              </li>
              <li
                className={`${activeTab == 2 && 'active-list-item'}`}
                onClick={() => {
                  setActiveTab(2)
                }}
              >
                <span>
                  <img src={activeTab == 2 ? briefcase : money4} />
                </span>
                Logistics
              </li>
              <li
                className={`${activeTab == 3 && 'active-list-item'}`}
                onClick={() => {
                  setActiveTab(3)
                }}
              >
                <span>
                  <img src={activeTab == 3 ? money5 : money1} />
                </span>
                Sales, Marketing and Supply Chain
              </li>
            </ul>
          </Col>
          <Col sm={6}>
            <div className="a-list">
              {MenuDataList.map((about, i) => {
                console.log('I', i)
                return (
                  activeTab == i && (
                    <div className={`list`} key={i}>
                      <div className="image-s">
                        <img src={about.image} />
                      </div>
                      <div
                        className="detail"
                        style={{
                          color: 'black',
                          textAlign: 'justify',
                        }}
                      >
                        <span>{about.detail}</span>
                      </div>
                    </div>
                  )
                )
              })}
            </div>
          </Col>
        </div>
      </Row>
    </Container>
  )
}
