import Frame1 from '../img/Frame1.png'
import Frame2 from '../img/Frame2.png'
import Frame3 from '../img/Frame3.png'
import Frame5 from '../img/Frame5.png'

export const services = [
  {
    name: 'Marketing Research Studies',
    detail:
      "We are in charge of gathering, storing and analyzing marketing data on a selected product then we make the right decision depending on the recommendations which are based on the study's outcomes.",
    type: 'Marketing Research Studies',
    image: Frame5,
  },
  {
    name: 'Registration',
    detail:
      'We handle the entire registration process in the Ministry of Health. Our strong relations and well-trained team facilitate and accelerate the registration process. Moreover, we are responsible for renewing companies and products registration.',
    type: 'Registration',
    image: Frame1,
  },
  {
    name: 'Logistics',
    detail: (
      <ul
        className="list-list"
        style={{
          listStyle: 'none',
          padding: 'unset',
        }}
      >
        <li>We carry out all stages of importing products:</li>
        <li>- Importing licenses</li>
        <li>- Shipping and Transportation</li>
        <li>- Products clearance</li>
      </ul>
    ),
    // 'We carry out all stages of importing products: -Products clearance -Shipping and Transportation -Products clearance',

    /* ,
     */

    type: 'Logistics',
    image: Frame3,
  },
  {
    name: 'Sales, Marketing and Supply Chain',
    detail:
      'In addition to our public sales, we are wide spreading around all over the market through our distribution channels, and that helps us achieving high distribution index and allows us to promote our products in the distant areas..',
    type: 'Sales, Marketing and Supply Chain',
    image: Frame2,
  },
]
