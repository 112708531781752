import flag from '../img/flag.png'
import eng from '../img/eng.png'

export const select = [
  {
    name: 'English',
    image: eng,
  },
  {
    name: 'Arabic',
    image: flag,
  },
]
