import { Container, Col, Row } from 'react-bootstrap'
import React from 'react'
import image210 from '../../img/image210.png'
import './SecondTab.css'
export const SecondTab = () => {
  return (
    <Container fluid>
      <div className="second">
        <Row>
          <div className="s-row">
            <Col sm={6} className="mobile2">
              <div className="s-desc">
                <h5>
                  To build close relation with our partners and invest it to
                  achieve the win-win principle through the strong presence and
                  true competition.
                </h5>
              </div>
            </Col>

            <Col sm={6} className="mobileView">
              <div className="s-image">
                <img src={image210} />
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
