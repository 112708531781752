import { Container, Col, Row } from 'react-bootstrap'
import React from 'react'
import './TimeOne.css'
import Time1 from '../../img/Time1.png'
export const TimeOne = () => {
  return (
    <Container fluid>
      <div className="One">
        <Row>
          <div className="one-row">
            <Col sm={6} className="mobile">
              <div className="one-desc">
                <h5>
                  <span>
                    V<span>I</span>NCA PHARMA
                  </span>
                  {''} was established in 2015 and started its work by
                  registering respiratory products and penetrating the market
                  gradually. Since then, Vinca pharma has grown to become the
                  company we know today as one of the important providers of
                  products in the pharmaceutical field.
                </h5>
              </div>
            </Col>

            <Col sm={6}>
              <div className="one-image" >
                <img src={Time1} />
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
