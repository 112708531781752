import React from 'react'
import './TimeTwo.css'
import Time2 from '../../img/Time2.png'
import { Container, Col, Row } from 'react-bootstrap'
export const TimeTwo = () => {
  return (
    <Container fluid>
      <div className="Two">
        <Row>
          <div className="two-row">
            <Col sm={6}>
              <div className="two-image">
                <img src={Time2} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="two-desc">
                <h5>
                  Many products were registered in that period and we added
                  hormones and contraceptive products to our portfolio. As a
                  result, we got higher market share in that period.
                </h5>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
