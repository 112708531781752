import React from 'react'
import './TimeFour.css'
import Time4 from '../../img/Time4.png'
import { Container, Col, Row } from 'react-bootstrap'
export const TimeFour = () => {
  return (
    <Container fluid>
      <div className="Four">
        <Row>
          <div className="four-row">
            <Col sm={6}>
              <div className="four-image">
                <img src={Time4} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="four-desc">
                <h5>
                  At the end of 2022, we decided to expand our business so we
                  opened a new office in Damascus Downtown, and we added
                  Oncology and Hematology Drugs categories to our portfolio.
                </h5>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
