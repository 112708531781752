import { Container, Col, Row } from 'react-bootstrap'
import React from 'react'
import './TimeThree.css'
import Time3 from '../../img/Time3.png'
export const TimeThree = () => {
  return (
    <Container fluid>
      <div className="Three">
        <Row>
          <div className="three-row">
            <Col sm={6} className="mobile">
              <div className="three-desc">
                <h5>
                  In these years,{' '}
                  <span>
                    V<span>I</span>NCA PHARMA
                  </span>
                  {''} continued its services but in a lower rate due to the
                  global pandemic COVID-19. However now we completely returned
                  back and we always looking forward the expansion and
                  development.
                </h5>
              </div>
            </Col>

            <Col sm={6}>
              <div className="three-image">
                <img src={Time3} />
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
