import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Default from '../../img/Default.png'
import Default1 from '../../img/Default1.png'
import Default2 from '../../img/Default2.png'
import Default3 from '../../img/Default3.png'
import './FourthTab.css'
import Frame from '../../img/Frame.png'
import CoreValueCard from '../CoreValueCard/CoreValueCard'
import { valuesData } from '../../data/values'
import { useState } from 'react'
export const FourthTab = () => {
  const [valuesDataState, setValuesDataState] = useState(valuesData)
  return (
    <Container>
      <Row>
        <div className="core-values-cards-container">
          {valuesDataState.map((valueItem, index) => {
            return <CoreValueCard valueItem={valueItem} key={index} />
          })}
        </div>
      </Row>
      {/* <div className="fourth">
        <Row>
          <div className="f-row1">
            <Col sm={4}>
              <div className="f-img">
                <img src={Default} />
              </div>
              <div className="content">
                <p>
                  Integrity, Trust, Respect and Transparency are our basics.
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="f-img">
                <img src={Default1} />
              </div>
              <div className="content">
                <p>
                  We believe that there are no limits to what we can achieve,
                  and we are always willing to explore new products, markets,
                  and opportunities.
                </p>
              </div>
            </Col>
            <Col sm={4}>
              <div className="f-img">
                <img src={Default2} />
              </div>
              <div className="content">
                <p>
                  We always innovate new solutions with speed and flexibility.
                </p>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="f-row2">
            <Col sm={6}>
              <div className="f-img">
                <img src={Default3} />
              </div>
              <div className="content">
                <p>
                  Starting business in an intelligent way is the secret of its
                  success.
                </p>
              </div>
            </Col>
            <Col sm={6}>
              <div className="f-img">
                <img src={Frame} />
              </div>
              <div className="content">
                <p>
                  Actual differences require creative thought, courage and
                  initiation.
                </p>
              </div>
            </Col>
          </div>
        </Row>
      </div> */}
    </Container>
  )
}
