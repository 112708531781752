import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Vector from '../../img/Vector.png'
import './Partners.css'
import mai1 from '../../img/mai1.png'
import mingcute from '../../img/mingcute.png'
export const Partners = () => {
  return (
    <Container fluid>
      <div className="p-wrapper">
        <Row>
          <div className="p-row">
            <Col>
              <div className="p-col">
                <div className="box">
                  <div className="partners">
                    <h1>Partners</h1>
                    <p>
                      <span>
                        V<span>I</span>NCA PHARMA
                      </span>{' '}
                      has partners from worldwide that enable us to develop our
                      business and offer our services perfectly. Our partners
                      are from Asia, Europe, and Latin America
                    </p>
                  </div>
                  <div className="contact">
                    <h1>Contact us</h1>
                    <div className="line" style={{ marginBottom: '1rem' }}>
                      <span>
                        <img src={mingcute} />
                      </span>
                      <span>United Arab Emirates, Ajman</span>
                    </div>
                    <div className="line" style={{ marginBottom: '1rem' }}>
                      <span>
                        <img src={mingcute} />
                      </span>
                      <span>
                        We have Offices in Syria, Iraq and Saudi Arabia
                      </span>
                    </div>
                    <div className="line" style={{ marginBottom: '1rem' }}>
                      <span>
                        <img src={mai1} />
                      </span>
                      <span>info@vinca-pharma.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
