import React from 'react'
import './About.css'
import { Container, Row, Col } from 'react-bootstrap'
import pills1 from '../../img/pills1.png'
import pills2 from '../../img/pills2.png'
import image from '../../img/image .png'
export const About = () => {
  return (
    <Container fluid>
      <div className="about-wrapper">
        <Row>
          <div className="a-row">
            <div className="a-col1">
              <Col sm={6}>
                <div className="a-title">About us</div>
                <div className="a-p">
                  V<span>I</span>NCA PHARMA
                  <span>
                    {''} is a private company that has a long-term of expertise
                    in the field of importing, marketing and disturbing
                    pharmaceutical products, which makes us one of most
                    competitive companies in quality and efficiency
                  </span>
                </div>
              </Col>
            </div>

            <Col sm={6}>
              <div className="a-col2">
                <div className="a-left">
                  <img src={pills1} alt="" />
                  <img src={pills2} alt="" />
                </div>
                <div className="a-right">
                  <img src={image} />
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
