import React from 'react'
import './Navbar.css'
import Logo from '../../img/Logo .png'
import mail from '../../img/mail.png'
import { select } from '../../data/select'
import { Container, Row, Col } from 'react-bootstrap'
import { useState } from 'react'
import NavSelect from '../Nav-Select/NavSelect'
const Navbar = () => {
  const [FlagDataList, setFlagDataList] = useState(select)
  return (
    <Container fluid>
      <div className="nav-wrapper">
        <Row>
          <div className="nav-row">
            {/* <Col sm={4} className="nav-mail">
              <div className="nav-mail-icon">
                <img src={mail} />
              </div>
              <span>info@vinca-pharma.com</span>
            </Col> */}
            <Col sm={12}>
              <div className="nav-logo">
                <img src={Logo} alt="" />
              </div>
            </Col>
            {/* <Col sm={4} className="lang">
              <button>English</button>
            </Col> */}
          </div>
        </Row>
      </div>
    </Container>
  )
}

export default Navbar
