import React from 'react'
import { useState } from 'react'
import './Timeline.css'
import TabContent from '../../Components/AllTabs/TabContent'
import TabNavItem from '../../Components/AllTabs/TabNavItem'
import { TimeOne } from '../../Components/TimeTabs/TimeOne'
import { TimeTwo } from '../../Components/TimeTabs/TimeTwo'
import { TimeThree } from '../../Components/TimeTabs/TimeThree'
import { TimeFour } from '../../Components/TimeTabs/TimeFour'
import Vector2 from '../../img/Vector2.png'

export const Timeline = () => {
  const [activeTab, setActiveTab] = useState('1')

  const handleTab1 = () => {
    setActiveTab('1')
  }
  const handleTab2 = () => {
    setActiveTab('2')
  }
  const handleTab3 = () => {
    setActiveTab('3')
  }
  const handleTab4 = () => {
    setActiveTab('4')
  }
  return (
    <div className="timeline">
      <div className="time-title">History Time line</div>
      <ul className="navline">
        <TabNavItem
          title={`2015 ${activeTab == 1 ? '- The Founding Year' : ''}`}
          id="1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="lines">
          <img src={Vector2} />
        </div>
        <TabNavItem
          // title="2018-2020"
          title={`2018-2020 ${activeTab == 2 ? '- Expansion Year' : ''}`}
          id="2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="lines">
          <img src={Vector2} />
        </div>
        <TabNavItem
          title={`2020-2022 ${activeTab == 3 ? '- Covid-19-Years' : ''}`}
          id="3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="lines">
          <img src={Vector2} />
        </div>
        <TabNavItem
          title={`2023 ${activeTab == 4 ? '- Plan for 2023' : ''}`}
          id="4"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="1" activeTab={activeTab}>
          <TimeOne />
        </TabContent>
        <TabContent id="2" activeTab={activeTab}>
          <TimeTwo />
        </TabContent>
        <TabContent id="3" activeTab={activeTab}>
          <TimeThree />
        </TabContent>
        <TabContent id="4" activeTab={activeTab}>
          <TimeFour />
        </TabContent>
      </div>
    </div>
  )
}
