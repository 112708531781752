import React from 'react'
import './ThirdTab.css'
import pills2 from '../../img/pills2.png'
import { Container, Col, Row } from 'react-bootstrap'
export const ThirdTab = () => {
  return (
    <Container fluid>
      <div className="third">
        <Row>
          <div className="t-row">
            <Col sm={6} className="mobile1">
              <div className="t-image">
                <img src={pills2} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="t-desc">
                <h5>
                  To improve health by selecting partners which have high
                  quality products and guarantee the continuous supply to
                  achieve better health outcomes and highly quality of life.
                </h5>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
