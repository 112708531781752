import './App.css'
import Navbar from './Components/Navbar/Navbar'

import { About } from './Sections/About/About'
import { Footer } from './Sections/Footer/Footer'
import { OurServices } from './Sections/OurServices/OurServices'
import { Partners } from './Sections/Partners/Partners'
import { Sales } from './Sections/Sales/Sales'
import { Tabs } from './Sections/Tabs/Tabs'
import { Timeline } from './Sections/Timeline/Timeline'

function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      <Tabs />
      <OurServices />
      <Timeline />

      <Partners />
      <Footer />
    </div>
  )
}

export default App
