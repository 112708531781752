import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../../img/Logo .png'
import './Footer.css'
export const Footer = () => {
  return (
    <Container fluid>
      <div className="f-wrapper">
        <Row>
          <div className="row-f">
            <Col sm={6}>
              <div className="col-f1">
                <img src={Logo} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="col-f2">
                <p>Copyright © 2023 Vinca pharma | All Rights Reserved</p>
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </Container>
  )
}
