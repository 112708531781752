import React from 'react'
import './Tab.css'
import { useState } from 'react'
import { FirstTab } from '../../Components/AllTabs/FirstTab'
import { SecondTab } from '../../Components/AllTabs/SecondTab'
import { ThirdTab } from '../../Components/AllTabs/ThirdTab'
import { FourthTab } from '../../Components/AllTabs/FourthTab'
import TabContent from '../../Components/AllTabs/TabContent'
import TabNavItem from '../../Components/AllTabs/TabNavItem'
export const Tabs = () => {
  const [activeTab, setActiveTab] = useState('tab1')
  const handleTab1 = () => {
    setActiveTab('tab1')
  }
  const handleTab2 = () => {
    setActiveTab('tab2')
  }
  const handleTab3 = () => {
    setActiveTab('tab3')
  }
  const handleTab4 = () => {
    setActiveTab('tab4')
  }
  return (
    <div className="tabs">
      <ul className="nav">
        <TabNavItem
          title="What We Do"
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Vision"
          id="tab2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Mission"
          id="tab3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <TabNavItem
          title="Core Values"
          id="tab4"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </ul>
      <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
          <FirstTab />
        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>
          <SecondTab />
        </TabContent>
        <TabContent id="tab3" activeTab={activeTab}>
          <ThirdTab />
        </TabContent>
        <TabContent id="tab4" activeTab={activeTab}>
          <FourthTab />
        </TabContent>
      </div>
    </div>
  )
}
